<template>
  <div class="nav_top_bar_container">
    <div v-if="upperDrawer" class="upper--drawer">
      {{ upperDrawer }}
      <uds-icon icon-name="chevron_right" color="#333" />
    </div>
    <NuxtLink
      v-for="subDrawer in subDrawersLinks"
      :key="subDrawer.id"
      class="sub_drawer"
      :class="{ '--isActive': isActive(subDrawer) }"
      :to="subDrawer.to"
    >
      <div v-if="subDrawer.isNew" class="sub_drawer-tag">New</div>
      {{ subDrawer.label }}
      <div v-if="subDrawer.count" class="sub_drawer-tag">
        {{ subDrawer.count }}
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  drawerPosition: string;
  state?: string;
  upperDrawer?: string;
}>();

const subDrawers = computed(() => useSubDrawersComposable().subDrawers);
const route = useRoute();

const subDrawersLinks = computed(() => {
  return subDrawers.value[props.drawerPosition]?.filter((d) => d.visibility !== false);
});

const storeComposable = useStoreComposable();
const activeStore = computed<any>(() => storeComposable.activeStore(props.state));

const activeTab = computed<string>(() => {
  return activeStore.value?.queryProperties?.tab ?? "";
});

function isActive(subDrawer: SubDrawerItem) {
  return subDrawer.hasTabCondition
    ? activeTab.value === subDrawer.tab || route?.query?.tab === subDrawer.tab
    : true;
}
</script>

<style lang="scss" scoped>
@use "@/assets/styles/subDrawers.scss";
</style>
